import React, { useState } from 'react';
import './App.css';

function App() {
  const backgroundImage = `${process.env.PUBLIC_URL}/bobbyface.png`;
  // const logoImage = `${process.env.PUBLIC_URL}/
  const eye1Image = `${process.env.PUBLIC_URL}/dexscreener.png`;
  const eye2Image = `${process.env.PUBLIC_URL}/pumplogo.png`;
  const rayImage = `${process.env.PUBLIC_URL}/raydium-removebg-preview.png`;
  const telegramImage = `${process.env.PUBLIC_URL}/telegramlogo.png`;
  const xImage = `${process.env.PUBLIC_URL}/xlogo.png`;
  // const image3Image = `${process.env.PUBLIC_URL}/

  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    navigator.clipboard.writeText('DvWsdSYnQr8CU1avymU2yCeUT7rbjyY8Ft13W5aGVhiS');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="App">
      <div className="background-container" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="content">
        {/* <img src={logoImage} alt="Logo" style={{ borderRadius: '20px', width: '50%', height: '50%' }} />
         */}
        <div className="icons-bar">
          <a href="https://dexscreener.com/solana/" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={eye1Image} alt="Dexscreener Logo" className="icon-image" />
          </a>
          <a href="https://pump.fun/DvWsdSYnQr8CU1avymU2yCeUT7rbjyY8Ft13W5aGVhiS" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={eye2Image} alt="Pump.fun Logo" className="icon-image" />
          </a>
          <a href="https://raydium.io/swap/" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={rayImage} alt="Raydium Logo" className="icon-image" />
          </a>
          <a href="https://t.me/BOTHonSolPortal" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={telegramImage} alt="Telegram Logo" className="icon-image" />
          </a>
          <a href="https://x.com/BOTHonSol" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={xImage} alt="X Logo" className="icon-image" />
          </a>
        </div>

        <footer className="App-footer" onClick={handleCopy}>
          <p className="contract-address">XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXpump</p>
            {copied && <span className="copied-message">Copied!</span>}
            {/* <img src={image3Image} alt="Image3" className="image3" /> */}
        </footer>
      </div>
    </div>
  );
}

export default App;